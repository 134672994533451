import React from 'react';
import { Link } from 'react-router-dom';
import './Projects.css';

const Projects = () => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="projects-container">
      <h1>My Projects</h1>

      <div className="project">
  <h2>
    <Link 
      to="/projects/financial-visualizer" 
      onClick={(e) => {
        e.preventDefault();
        openInNewTab('/projects/financial-visualizer');
      }}
    >
      Stock Panels
    </Link>
  </h2>
  <p>Stock Panels delivers a modern, glassmorphic view of stock market data, presenting essential financial insights through stylish and interactive panels. Get quick access to up-to-date information and make smarter investment decisions with ease. NOTE: Please only enter stock tickers. eg. AAPL, TSLA, RELIANCE.BO</p>
</div>

      <div className="project">
        <h2>
          <Link 
            to="/projects/indian-stock-market-timeline" 
            onClick={(e) => {
              e.preventDefault();
              openInNewTab('/projects/indian-stock-market-timeline');
            }}
          >
            Indian Stock Market: A Timeline of Key Events
          </Link>
        </h2>
        <p>A dynamic timeline presentation, coded in JavaScript and React, that illustrates the history and key events of the Indian stock market from the 1850s to the present day. The timeline features custom animations and particle effects rendered on an HTML5 canvas, optimized for both desktop and mobile devices.</p>
      </div>

      <div className="project">
        <h2><a href="https://financetools.streamlit.app/" target="_blank" rel="noopener noreferrer">Financial Calculators</a></h2>
        <p>Financial Calculator App built with Streamlit! This app offers a set of interactive tools designed to help you easily calculate and visualize your financial investments and loan repayments.</p>
      </div>

      <div className="project">
        <h2><a href="https://ifrsassistant.streamlit.app/" target="_blank" rel="noopener noreferrer">IFRS Assistant</a></h2>
        <p>IFRS Assistant designed to handle any IFRS-related queries, providing instant and accurate responses.</p>
      </div>

      <div className="project">
        <h2><a href="https://stockforecast-vik.streamlit.app/" target="_blank" rel="noopener noreferrer">NSE Stock Forecast App</a></h2>
        <p>The Indian Stock Forecast App is an application developed to predict the future stock price of NIFTY 50 companies, based on the Prophet time series model. An app that allows one to choose any stock out of the NIFTY 50 index, get a brief about the company, and generate a forecast of up to 4 years.</p>
      </div>
    </div>
  );
};

export default Projects;