import React, { useEffect } from 'react';
import './About.css';

const About = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.goat1000.com/tagcanvas.min.js';
    script.onload = () => {
      if (window.TagCanvas) {
        try {
          window.TagCanvas.Start('myCanvas', 'tags', {
            textColour: '#FFFFFF',
            outlineColour: '#ff00ff',
            reverse: true,
            depth: 0.8,
            maxSpeed: 0.02,
            wheelZoom: false,
            weight: true,
            weightFrom: 'data-weight',
            textHeight: 25,
            radiusX: 1.1,
            radiusY: 1.1,
            radiusZ: 1.1,
            initial: [0.8, -0.8],
            centreImage: '',
            freezeActive: true,
            shuffleTags: true,
            fadeIn: 1000,
            padding: 0,
            bgRadius: 1.0,
            shadow: '#000',
            shadowBlur: 1,
          });
        } catch (e) {
          console.log('Canvas error:', e);
        }
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="about-container">
      <div className="about-content">
        <div className="about-text">
          <h1>About Me</h1>
          <p>I combine a solid foundation in accounting, tax, and finance with a practical understanding of technology. My expertise includes Corporate Tax, Tax Effect Accounting, and IFRS, alongside a strong interest in Business Valuations and skills in Financial Modeling.</p>
          <p>In addition to my finance and tax knowledge, I have hands-on experience with programming languages like Python, CSS, JavaScript, HTML, and Node.js. I can design and develop websites, automate tasks, and create basic tools that improve efficiency. My familiarity with platforms like Power BI, Tableau, and VBA helps me to turn data into actionable insights.</p>
          <p>Although I'm self-taught in coding, I have successfully built websites and tools, leveraging platforms like GitHub. I'm also continuously expanding my skill set through courses in programming, Artificial Intelligence, and Machine Learning on platforms like Coursera and edX. My goal is to use these combined skills to deliver practical, tech-enabled solutions that enhance financial and tax operations.</p>
        </div>
        <div className="sphere-container">
          <canvas id="myCanvas" width="600" height="600">
            <p>Anything in here will be replaced on browsers that support the canvas element</p>
          </canvas>
          <div id="tags">
            <ul>
              <li><a href="#" data-weight="50">Python</a></li>
              <li><a href="#" data-weight="44">IFRS</a></li>
              <li><a href="#" data-weight="60">Tax</a></li>
              <li><a href="#" data-weight="40">DCF</a></li>
              <li><a href="#" data-weight="50">Finance</a></li>
              <li><a href="#" data-weight="60">Accounting</a></li>
              <li><a href="#" data-weight="50">Tech</a></li>
              <li><a href="#" data-weight="60">CSS</a></li>
              <li><a href="#" data-weight="40">JavaScript</a></li>
              <li><a href="#" data-weight="50">SQL</a></li>
              <li><a href="https://www.credly.com/badges/324c2fbe-56c6-4ded-8e22-0735a35736b0/linked_in_profile" target="_blank" rel="noopener noreferrer" data-weight="40" className="orange-link">🔗Project Management</a></li>
              <li><a href="https://www.credly.com/badges/ed7f21fb-79e7-47ad-9a44-ac42bb200271/public_url" target="_blank" rel="noopener noreferrer" data-weight="50" className="orange-link">🔗AICPA-IFRS</a></li>
              <li><a href="https://credentials.cfainstitute.org/9127f75e-c8a1-499e-8397-6116d73c23a2#gs.e640b4" target="_blank" rel="noopener noreferrer" data-weight="50" className="orange-link">🔗CFA-IFC</a></li>
              <li><a href="#" data-weight="45" className="orange-link">IIM-C EPGM</a></li>
              <li><a href="https://credentials.edx.org/credentials/d308e4d860f6410ca38149144acf6233/" target="_blank" rel="noopener noreferrer" data-weight="40" className="orange-link">🔗MBA</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
