import React, { useEffect, useState } from 'react';
import './Blog.css';

const Blog = ({ isDarkMode }) => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 6;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `https://thefinigma.com/wp-json/wp/v2/posts?per_page=${postsPerPage}&page=${currentPage}`
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        const data = await response.json();
        const totalPosts = response.headers.get('X-WP-Total');
        setTotalPages(Math.ceil(totalPosts / postsPerPage));
        setPosts(data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className={`blog-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <h1>Blog Posts</h1>
      {/* Navigation Arrows for Mobile */}
      <div className="mobile-navigation">
        <button
          className="mobile-prev-arrow"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          &larr;
        </button>
        <button
          className="mobile-next-arrow"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          &rarr;
        </button>
      </div>
      <div className="bookshelf">
        {posts.length > 0 ? (
          posts.map((post) => (
            <a
              key={post.id}
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
              className="book"
            >
              <span
                className="book-title"
                dangerouslySetInnerHTML={{ __html: post.title.rendered }}
              />
            </a>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
      {!isLoading && (
        <div className="pagination">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default Blog;
