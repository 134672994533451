import React, { useState, useEffect, useRef } from 'react';
import './IndianStockMarketTimeline.css';

const events = [
  { year: "1850s", title: "The Informal Beginnings", description: "A group of 22 brokers begins informal trading under a Banyan tree at Horniman Circle in Mumbai. This marks the genesis of the Indian stock market, where trading was focused on shares of banks and cotton presses." },
  
  { year: "1861-1865", title: "Cotton Boom and Crash", description: "The American Civil War leads to a surge in demand for Indian cotton, causing a speculative boom in cotton stocks. The war's end in 1865 triggers India's first major stock market crash as cotton prices collapse." },
  
  { year: "1875", title: "Formalization of Bombay Stock Exchange (BSE)", description: "The informal group of brokers formalizes their operations by establishing the 'Native Share & Stockbrokers Association,' later known as the Bombay Stock Exchange (BSE), Asia's first stock exchange." },
  
  { year: "1908", title: "Establishment of Calcutta Stock Exchange", description: "The Calcutta Stock Exchange is established to cater to the growing financial activities in Eastern India, particularly in the jute and tea industries." },
  
  { year: "1920", title: "Establishment of Madras Stock Exchange", description: "The Madras Stock Exchange is founded, serving the southern regions and supporting the trading of shares in industries like textiles and plantation crops." },
  
  { year: "1947", title: "Post-Independence Market Reforms", description: "Following India's independence, the stock market sees increased regulation and oversight as the government focuses on building a strong financial system." },
  
  { year: "1956", title: "Securities Contracts (Regulation) Act", description: "The Government of India passes the Securities Contracts (Regulation) Act, giving legal recognition to stock exchanges and regulating securities trading across the country." },
  
  { year: "1963", title: "Establishment of Unit Trust of India (UTI)", description: "UTI is established to promote savings and investment among the public, launching India's first mutual fund, US64, which becomes a major vehicle for small investors." },
  
  { year: "1978", title: "Nationalization of Banks", description: "The Indian government nationalizes several major banks to consolidate the banking sector and ensure greater control over financial resources, significantly impacting the financial markets." },
  
  { year: "1986", title: "Launch of BSE Sensex", description: "The BSE introduces the Sensex, a benchmark index tracking the performance of 30 well-established companies listed on the exchange, becoming a critical indicator for investors." },
  
  { year: "1988", title: "Formation of SEBI", description: "The Securities and Exchange Board of India (SEBI) is established to regulate the securities market, aiming to protect investor interests and improve market transparency." },
  
  { year: "1991", title: "Economic Liberalization", description: "India undergoes economic liberalization, opening its economy to foreign investments, leading to increased trading volumes, greater market liquidity, and the entry of foreign institutional investors." },
  
  { year: "1992", title: "Establishment of National Stock Exchange (NSE)", description: "The National Stock Exchange (NSE) is established as a modern, electronic platform to facilitate stock trading, introducing screen-based trading in 1994." },
  
  { year: "1993", title: "SEBI Gains Statutory Powers", description: "Following the Harshad Mehta scam, SEBI is granted statutory powers, leading to significant improvements in market transparency, investor protection, and overall market stability." },
  
  { year: "1994", title: "NSE Introduces Electronic Trading", description: "NSE introduces fully automated, screen-based trading, revolutionizing the Indian stock market by making it more accessible and leading to a sharp increase in trading volumes." },
  
  { year: "1999", title: "Introduction of the Depositories Act", description: "The Depositories Act is passed, allowing for the dematerialization of shares, facilitating electronic trading, and reducing the risks associated with physical share certificates." },
  
  { year: "2000", title: "Rise of Online Trading", description: "The advent of online trading democratizes access to the stock market, allowing retail investors to trade directly from their computers, leading to a surge in market participation." },
  
  { year: "2006", title: "BSE Sensex Crosses 10,000", description: "The BSE Sensex crosses the 10,000 mark for the first time, reflecting strong economic growth in India and rising investor confidence." },
  
  { year: "2008", title: "Global Financial Crisis", description: "The Indian stock market is heavily impacted by the global financial crisis, with the Sensex dropping by over 50% from its peak. However, the market demonstrates resilience by recovering in subsequent years." },
  
  { year: "2010", title: "Market Reforms and Growth", description: "The Indian stock market sees the introduction of several reforms aimed at improving market efficiency and transparency, including the adoption of International Financial Reporting Standards (IFRS)." },
  
  { year: "2014", title: "SEBI Implements New Reforms", description: "SEBI introduces reforms to improve corporate governance, regulate insider trading, and enhance disclosure requirements, contributing to greater investor confidence." },
  
  { year: "2017", title: "Implementation of GST", description: "The Goods and Services Tax (GST) is implemented, streamlining India's tax structure and impacting various sectors, leading to short-term volatility in the stock market." },
  
  { year: "2020", title: "COVID-19 Pandemic", description: "The COVID-19 pandemic causes a sharp downturn in the Indian stock market, with the Sensex experiencing one of its largest single-day falls. However, the market stages a strong recovery by the end of the year." },
  
  { year: "2022", title: "India's Inclusion in Global Indices", description: "India is included in the MSCI Emerging Markets Index, attracting substantial foreign investments and further integrating the Indian stock market into the global financial system." },
  
  { year: "2024", title: "Continued Growth", description: "The Indian stock market continues to grow, supported by technological advancements, increased participation from retail investors, and a favorable economic environment." }
];



const IndianStockMarketTimeline = () => {
  const [currentEvent, setCurrentEvent] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  const requestRef = useRef();

  useEffect(() => {
    if (isStarted) {
      const timer = setTimeout(() => {
        fadeOut();
      }, 10000); // Start fade out after 10 seconds
      return () => clearTimeout(timer);
    }
  }, [currentEvent, isStarted]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let particles = [];

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const createParticles = () => {
      const particleCount = 3000; // Number of particles
      const centerX = canvas.width / 2; // Center of the canvas
      const centerY = canvas.height / 2;
      const amplitudeX = 150; // Width of the loops
      const amplitudeY = 80; // Height of the loops
      const frequency = 0.002; // Frequency of the wave

       for (let i = 0; i < particleCount; i++) {
        const angle = i * frequency; // Angle for the infinite loop
        const x = centerX + amplitudeX * Math.sin(angle) * Math.cos(angle);
       const y = centerY + amplitudeY * Math.sin(angle) * Math.sin(angle);

      particles.push({
      x: x,
      y: y,
      radius: Math.random() * 0.5 + 0.3, // Random radius for particles
      speed: Math.random() * 0.5 + 0.1, // Speed of particle movement
      angle: angle,
    });
  }
};


    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgb(0, 0, 255)';
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fill();

        particle.x += Math.cos(particle.angle) * particle.speed;
        particle.y += Math.sin(particle.angle) * particle.speed;

        if (particle.x < 0 || particle.x > canvas.width) particle.angle = Math.PI - particle.angle;
        if (particle.y < 0 || particle.y > canvas.height) particle.angle = -particle.angle;
      });
      requestRef.current = requestAnimationFrame(drawParticles);
    };

    resizeCanvas();
    createParticles();
    drawParticles();

    window.addEventListener('resize', resizeCanvas);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  const fadeOut = () => {
    setOpacity(0);
    setTimeout(() => {
      setCurrentEvent((prevEvent) => (prevEvent + 1) % events.length);
      setOpacity(1);
    }, 1500); // Wait for fade out to complete before changing event
  };

  const handleStart = () => {
    setTimeout(() => {
      setIsStarted(true);
      if (audioRef.current) {
        audioRef.current.play();
      }
    }, 1500); // 1-second delay before starting
  };

  return (
    <div className="timeline-wrapper">
      <canvas ref={canvasRef} className="background-canvas"></canvas>
      <h1 className="title">Indian Stock Market: A Timeline of Key Events</h1>
      {isStarted ? (
        <div className="event-display" style={{ opacity }}>
          <h2>{events[currentEvent].year}</h2>
          <h3>{events[currentEvent].title}</h3>
          <p>{events[currentEvent].description}</p>
        </div>
      ) : (
        <button onClick={handleStart} className="start-button">Start</button>
      )}
      <audio ref={audioRef} loop>
        <source src="/Time.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default IndianStockMarketTimeline;