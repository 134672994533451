import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaLinkedin, FaTwitter, FaGithub, FaHome, FaUser, FaProjectDiagram, FaBlog } from 'react-icons/fa';
import './Navbar.css';

const Navbar = () => {
  return (
    <>
      <header className="navbar">
        <div className="navbar-container">
          <NavLink to="/" className="navbar-logo">
            VL
          </NavLink>
          <nav className="nav-menu desktop-menu">
            <ul className="nav-list">
              <li className="nav-item"><NavLink exact to="/" className="nav-links">Home</NavLink></li>
              <li className="nav-item"><NavLink to="/about" className="nav-links">About</NavLink></li>
              <li className="nav-item"><NavLink to="/projects" className="nav-links">Projects</NavLink></li>
              <li className="nav-item"><NavLink to="/blog" className="nav-links">Blog</NavLink></li>
            </ul>
          </nav>
          <div className="social-icons desktop-social">
            <a href="https://www.linkedin.com/in/vikramlingam/" target="_blank" rel="noopener noreferrer" className="nav-links"><FaLinkedin /></a>
            <a href="https://x.com/vikram_lingam" target="_blank" rel="noopener noreferrer" className="nav-links"><FaTwitter /></a>
            <a href="https://github.com/vikramlingam" target="_blank" rel="noopener noreferrer" className="nav-links"><FaGithub /></a>
          </div>
        </div>
      </header>
      <nav className="mobile-bottom-nav">
        <NavLink exact to="/" className="mobile-nav-item">
          <FaHome />
          <span></span>
        </NavLink>
        <NavLink to="/about" className="mobile-nav-item">
          <FaUser />
          <span></span>
        </NavLink>
        <NavLink to="/projects" className="mobile-nav-item">
          <FaProjectDiagram />
          <span></span>
        </NavLink>
        <NavLink to="/blog" className="mobile-nav-item">
          <FaBlog />
          <span></span>
        </NavLink>
      </nav>
      <div className="mobile-social">
        <a href="https://www.linkedin.com/in/vikramlingam/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        <a href="https://x.com/vikram_lingam" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
        <a href="https://github.com/vikramlingam" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
      </div>
    </>
  );
};

export default Navbar;