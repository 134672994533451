import React, { useEffect, useState } from 'react';
import './Home.css';

const Home = () => {
  const [showName, setShowName] = useState(false);

  useEffect(() => {
    const totalTypingDuration = 0.5 * codeLines.length * 1200; // Total duration of typing effect

    // Trigger showing the name after typing is complete
    const timer = setTimeout(() => {
      setShowName(true);
    }, totalTypingDuration);

    return () => clearTimeout(timer);
  }, []);

  const codeLines = [
    "class Learn:",
    "    def __init__(self):",
    "        self.skills = ['Finance', 'Tax', 'Valuation']",
    "        self.tech = ['Python', 'JS', 'CSS']",
    "",
    "    def code(self):",
    '        return "Finance + Tech = Innovation"',
    "",
    "    def profile(self):",
    '        return f"{\' & \'.join(self.skills)} + Tech = Learning"',
    "",
    "vikram = Learn()",
    "",
    "",
    "print(vikram.code())",
    "",
    "",
    "print(vikram.profile())"
  ];

  return (
    <div className={`home-container ${showName ? 'show-name' : ''}`}>
      <div className="code-container">
        <pre className="typewriter-effect">
          {codeLines.map((line, index) => (
            <span key={index} className="line" style={{ animationDelay: `${index * 0.5}s` }}>
              {line}
            </span>
          ))}
        </pre>
      </div>
      <h1 className="reveal-name">Vikram Lingam</h1>
    </div>
  );
};

export default Home;
