import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Home from '../pages/Home';
import About from '../pages/About';
import Projects from '../pages/Projects';
import Blog from '../pages/Blog';
import IndianStockMarketTimeline from '../components/IndianStockMarketTimeline';
import FinancialVisualizer from '../components/FinancialVisualizer';

function MainContent() {
  const location = useLocation();  // This is now inside the Router context

  // Conditionally hide the particles for Financial Visualizer
  const shouldHideParticles = location.pathname === '/projects/financial-visualizer';

  return (
    <>
      {!shouldHideParticles && <Navbar />}  {/* Conditionally render Navbar */}
      <main className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/projects/indian-stock-market-timeline" element={<IndianStockMarketTimeline />} />
          <Route path="/projects/financial-visualizer" element={<FinancialVisualizer />} />
        </Routes>
      </main>
    </>
  );
}

export default MainContent;
